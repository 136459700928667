import React, { useEffect, useState, useRef, useCallback} from 'react';
import './product.css';
import Zoom from 'react-medium-image-zoom';
import Share from '../../assets/share.png';
import Report from '../../assets/report.png';
import Footer from '../../Components/Footer/footer';
import Modal from 'react-modal';
import ABI from '../../assets/CreathABI.json'
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';
import SlideCard from '../../Components/SliderCard/slidercard';
// import { useEthersProvider } from '../../ethersProviderUtils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams } from 'react-router-dom';
import Like from '../../Components/Like/like';



const Product = ({library, setProduct, setArtist, Artss, Medium, Artists, Collections})=>{
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [Arts, setArts] = useState([]);
    const [liked, setLiked] = useState(false)
    const [likeCount, setLikeCount] = useState(0)
    const [artist, setArtArtist] = useState([])
    const [category, setCategory] = useState("")
    const [copied, setCopied] = useState(false)
    const [description, setDescription] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    // const [loading2, setLoading2] = useState(true)
    // const [usable, setUsable] =  useState(0)
    const [isSold, setSold] =  useState(false)
    // const [canBuy, setBuy] = useState(true)
    const [dialogue, setDialogue] = useState(false)
    const [artistWork, setArtistWork] = useState([])
    const [colAddress, setColAddress] = useState(""); //This is the address we use for miniting and checking the owner of an artwork.
    // const ethersProvider = useEthersProvider();
    // const contractAddress =  "0xEE241Ef58821530b30F5c0f493C0c2a5cd3bea2F"
    const { id } = useParams();
    const user_id = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser')).id
    : null
    const navigate = useNavigate()
    const videoRef = useRef(null);
    Modal.setAppElement('#root');

    const setModalStatus = (n)=>{
        setIsOpen(n);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(()=>{
        if (videoRef.current) {
            videoRef.current.load();
            videoRef.current.play();
        }
    },[id])


    useEffect(()=>{
        const arts = Artss;
        const artists = Artists;
        const medium = Medium;
        let artist_work = [];
        let test = []
        

        for (let i = 0; i < arts.length; i++){
            if(`${arts[i].id}` === `${id}`){
                setArts(arts[i])
                test = arts[i]
                if(arts[i].collection_id !== null || arts[i].collection_id !== ""){
                    for(let j = 0; j < Collections.length; j++){
                        if (`${Collections[j].id}` === arts[i].collection_id){
                            setColAddress(Collections[j].nft_address)
                            checkStatus()
                        }
                    }
                }
                else{
                    setColAddress("0x4DF3Fbf82df684A16E12e0ff3683E6888e51B994")
                    checkStatus()
                }
                for(let k = 0; k < artists.length; k++){
                    if(`${artists[k].id}` === `${arts[i].artist_id}`){
                        setArtArtist(artists[k])
                        for (let l = 0; l < arts.length; l++){
                            if(`${arts[l].artist_id}` === `${artists[k].id}`){
                                if(`${arts[l].id}`=== `${arts[i].id}`){
                                    continue
                                }
                                else{
                                    artist_work.push(arts[l])
                                }
                            }
                        }
                        setArtistWork(artist_work)
                    }
                }
                for(let j =0; j< medium.length; j++ ){
                    if(arts[i].category_id === `${medium[j].id}`){
                        setCategory(medium[j].name)
                    }
                }
            }      
        }

        if( test.length === 0){
            setError(true)
        }
        
    },[id, Arts.collection_id])

    const checkStatus = async()=>{
        if(localStorage.getItem('isLoggedIn') === "true"){
            const ContractInstance = new ethers.Contract(colAddress, ABI, library)
            let id = ethers.BigNumber.from(Arts.nft_id)
            let Txn = await ContractInstance.ownerOf(id)
            if(Txn === "0x33B5E1DaF11b12103682fB77031111736aADAa5C"){
                setSold(false)
            }
            else{
                setSold(true)
            }
        }
    }

    const fetchLikeCount = useCallback(async () => {
        try {
          const response = await fetch(`https://creath.tech/api/arts/favorite-count/${id}`);
          const data = await response.json();
          setLikeCount(data.data);
        } catch (error) {
          console.error('Error fetching like count:', error);
        }
    }, [id]);


    const fetchLikedStatus = useCallback(async () => {
        if(user_id){
            try {
                const response = await fetch(`https://creath.tech/api/arts/favorite-status/${user_id}/${id}}`);
                const data = await response.json();
                setLiked(data.hasLiked);
            } catch (error) {
                console.error('Error fetching liked status:', error);
            }
        }
    }, [id, user_id]);

    useEffect(()=>{
        fetchLikeCount();
        if (user_id) {
            fetchLikedStatus();
        }
    },[id])


    const showArtist =  ()=>{
        navigate(`/artistProfile?id=${Arts.artist_id}`)
    }


    const data= new FormData()
    data.append("comment", description)
    data.append("user_id", artist.id)
    data.append("art_id", Arts.id)

    const handleLike = useCallback(async () => {
        if(localStorage.getItem('isLoggedIn') === "true" && user_id){
          
            try {
                let data = new FormData()
                data.append('art_id', `${id}`)
                data.append('user_id', `${user_id}`)

                const response = liked ? await fetch(`https://creath.tech/api/arts?art_id=${id}&user_id=${user_id}`, {
                    method: 'DELETE',
                  }) : await fetch('https://creath.tech/api/favorites',{
                    method: 'POST',
                    body: data
                })

                const status = await response.json();
                if (response.ok) {
                    fetchLikedStatus();
                    fetchLikeCount();
                } else {
                    console.error('Error updating like:', status.error);
                }
            } catch (error) {
                console.error('Error updating like:', error);
            }
        }
        else{
            setModalStatus()
        }
    },[liked, fetchLikeCount, fetchLikedStatus, id, user_id])

    const changeDescription = (e)=>{
        setDescription(e.target.value)
    }

    const changePage = ()=>{
        navigate(`/checkout/${Arts.id}`)
        localStorage.setItem('Art', JSON.stringify(Arts))
    }

    const copyProduct = ()=>{
        navigator.clipboard.writeText(window.location.href)
        setCopied(true)
        setDialogue(true)
        setTimeout(()=>{
            setCopied(false)
            setDialogue(false)
        },2000)
    }

    const media = String(Arts.art_image);
    let file = media.includes("mp4") ? media : media.substr(0, media.lastIndexOf(".")) + ".webp";
    let adding = "q_20/"
    let newString = file.slice(0, 29) + adding + file.slice(29);
  

    const submitFlag = ()=>{
        setLoading(true)
        fetch("https://creath.tech/api/flags",{
            method: "POST",
            body: data
        })
        .then(response => response.json())
        .then((data)=>{
            if(data.message === "Item Flaggered!" || data.message === "This item is already flagered by you!"){
                navigate('/marketplace')
                setLoading(false)
            }
        })
        .catch((e)=>{
            setLoading(false)
            
        })
    }

    const checkSold = (prod)=>{
        let EE = ['58', '21', '23', '27', '89', '39', '25', '118', '115', '67', '59', '6', '117', '110', '109'];
        if(`${prod.collection_id}` ===  "12"){
            if(EE.includes(`${prod.nft_id}`)){
                return true
            }
            else{
                return false
            }
        }
        else{
            return false
        }
    }
    

    return(
        <div className='productMain'>
            {
                error ?
                <div className='four'>
                    <h1>404</h1>
                    <p>Product not found, you can reload or go back to home</p>
                    <button onClick={()=>{navigate('/')}}>Go to home</button>
                </div>
                :
                <>
                    <div className={dialogue ? "dialogue showDialogue" : "dialogue"}>
                        <p>Copied to clipboard</p>
                    </div>
                    <div className='product_info'>
                        {
                            media.includes("mp4") ?
                            <video autoPlay={true} muted={true} loop ref={videoRef} playsInline>
                                <source src={`${media}`} type="video/mp4" />
                            </video>
                            :
                            <LazyLoadImage  src={newString} alt="Artwork"/>
                        }
                        <div className='product_text'>
                            <h1>{Arts.name}</h1>
                            <p className='product_artist' onClick={showArtist}>Created by: {Arts.artist_name}</p>
                            <div className='likes'>
                                <Like like={liked} onClick = {handleLike}/>
                                <p>{likeCount}</p>
                            </div>
                            <p className='product_price'>{Arts.collection_id === "7" ? "Not for Sale" : Arts.price + " USDC"}</p>
                            {/* <div className='physical_copy'>
                                <LazyLoadImage src={Picture} alt="Physical copy"/>
                                <p>{Arts.has_physical_copy === "0" ? "Physical Copy Unavailable" : "Physical Copy Available"}</p>
                            </div>  */}
                            <button onClick={changePage} disabled={isSold === true  || Arts.collection_id === "7" || checkSold(Arts)  ? true : false}>{isSold === true  || Arts.collection_id === "7" || checkSold(Arts) ? "Sold" : "Buy Now"}</button>
                            <div className='product_action'>
                                <div title={copied ? "Copy to clipboard" : "Copied"} className='product_share' onClick={copyProduct} role='button'>
                                    <LazyLoadImage src={Share} alt="Share the product"/>
                                </div>
                                <div onClick={()=>{setModalStatus(true)}} title='Flag Artwork' className='product_report' role='button'>
                                    <LazyLoadImage src={Report} alt="Report the product"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='product_description'>
                        <div className='prod_desc_text'>
                            <h1>Description</h1>
                            <p>{Arts.description}</p>
                        </div>  
                        <div className='prod_desc_info'>
                            <div className='items'>
                                <p className='prop'>Medium</p>
                                <p>{category}</p>
                            </div>
                            <div className='items'>
                                <p className='prop'>Location</p>
                                <p>{Arts.artist_location}</p>
                            </div>
                            <div className='items'>
                                <p className='prop'>Token ID</p>
                                <p>{Arts.nft_id}</p>
                            </div>
                            <div className='items'>
                                <p className='prop'>Dimension</p>
                                <p>{Arts.dimension}</p>
                            </div>
                        </div>

                    </div>
                    <div className='topNft'>
                        <h1>More Artworks From {artist.username}</h1>
                        <div className='top'>
                            {
                                artistWork.length !== 0 ?
                                <>
                                    {artistWork.map((el,i)=>{
                                        if(i <= 2){
                                            return <SlideCard artistName = {el.artist_name} physical = {el.has_physical_copy} key={el.created_at} id={el.id} img={`${el.art_image}`} title={el.name} description={el.description} artist={el.artist_id} price={el.price} style={{color: "red"}} setProduct = {setProduct} setArtist = {setArtist} Art={el} /> 
                                        }
                                        return null
                                    })}
                                    
                                </>
                                :
                                <></>
                            }
                        </div>
                        <button onClick={()=>{navigate('/marketplace')}}>See More</button>
                    </div>
                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >   
                        <div className='creatorForm'>
                            <h2 className='creatorHeader' ref={(_subtitle) => (subtitle = _subtitle)}>Report Artwork</h2>
                            <div className='creatorFormInput'>
                                <p>{Arts.name}</p>
                                <textarea onChange={changeDescription} placeholder='Enter the reason for this flag' />
                                <button disabled = {description === "" ? true : false} className={loading ? "whiteloading" : ""} onClick={submitFlag}>Submit</button>
                            </div>
                        </div>
                    </Modal>
                    <div className='prodFooter'>
                        <Footer/>
                    </div>
                </>
            }
        </div>
    )
}

export default Product
