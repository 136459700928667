import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate} from "react-router-dom";
import Footer from '../../Components/Footer/footer';
import './blogpage.css'



const BlogPage = ()=>{
    const { id } = useParams();
    const [post, setPost] = useState('');
    const [error, setError] = useState(false)
    const navigate = useNavigate()

    useEffect(()=>{
        fetch(`https://creath.tech/api/blogs/${id}`)
        .then(response => response.json())
        .then((data)=>{
            if(data){
                setPost(data.data);
                sessionStorage.setItem('currentBlog', JSON.stringify(data.data))
            }
        })
        .catch((e)=>{
            setError(true)  
        })
    },[])

    return(
        <div className='blogMain'>
            {
                error ?
                <div className='four'>
                    <h1>404</h1>
                    <p>Blogpost not found, you can reload or go back to home</p>
                    <button onClick={()=>{navigate('/')}}>Go to home</button>
                </div>
                :
                <div className='mainBlog'>
                    {
                        post ?
                        <div className='blogPost'>
                            <LazyLoadImage  src={`${(post.cover_image.substr(0, post.cover_image.lastIndexOf(".")) + ".webp").slice(0,29)}q_80/${(post.cover_image.substr(0, post.cover_image.lastIndexOf(".")) + ".webp").slice(29)}`} alt={post.description}/>
                            <h1>{post.title}</h1>
                            {Parser(post.content)}
                        </div>
                        :
                        <div className='loadingView'>
                            <div className='loader'></div>
                            <div className='loader'></div>
                            <div className='loader'></div>
                            <div className='loader'></div>
                            <div className='loader'></div>
                            <div className='loader'></div>
                            <div className='loader'></div>
                        </div>
                    }
                </div>
            }
            <Footer/>
        </div>
    )
}

export default BlogPage