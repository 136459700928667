import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const ProfileArtCard = (props)=>{

    const [newFormat, setNewFormat] = useState("")

    const navigate = useNavigate();

    useEffect(()=>{

        if(!props.img.includes("mp4")){
            let file = props.img.substr(0, props.img.lastIndexOf(".")) + ".webp";
            let adding = "q_50/"
            let newString = file.slice(0, 29) + adding + file.slice(29);
            setNewFormat(newString);
        }else{
            let adding = "q_50/"
            let newString = props.img.slice(0, 29) + adding + props.img.slice(29);
            setNewFormat(newString);
        }
    
    },[])

    const showArtist =  ()=>{
        navigate(`/artistProfile?id=${props.artist}`)
    }

    return(
        <div className='artMain'>
            <div className='artImage'>
                {
                    props.img.includes('mp4') ? 
                    <video width="750" height="500"  autoPlay={true} muted={true} loop controls={false} playsInline>
                        <source src={props.img} type="video/mp4" />
                    </video>
                    :
                     <LazyLoadImage
                    alt={props.title}
                    
                    src={newFormat}
                />
                }
            </div>
            <div className='artText'>
                <p className='artTitle'>{props.title}</p>
                <div className='subArtText'>
                <p className='artistName' onClick={showArtist}>{props.artistName}</p>
                <hr />
                <div className="priceLike">
                    <p className='artPrice'>{props.price} USDC</p>
                </div>
                </div>
            </div>
        </div>
    )

}


export default ProfileArtCard