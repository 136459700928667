import React, { useState,useEffect } from 'react';
import './mobileNav.css';
import { slide as Menu } from 'react-burger-menu';
import Bar from '../../assets/bar.png'
import { useNavigate } from 'react-router-dom';
import Logo  from '../../assets/creathscg.svg'
import Web3 from 'web3';
import { LazyLoadImage } from 'react-lazy-load-image-component';



const MobileHeader = (props)=>{
    const [account, setAccount] = useState(null)
    const [ menu, setMenu ] = useState(false)
    const web3 = new Web3(window.ethereum);
    const navigate = useNavigate();

    const changePage = (page,pageName)=>{
        navigate(page)
        props.setCurrentPage(pageName)
        sessionStorage.setItem("currentPage",`${pageName}`)
        setMenu(false)
    }

    const logOut = ()=>{
        if(localStorage.getItem('connector')==="injected"){
            web3.setProvider(null);
            localStorage.removeItem('account');
            props.setCurrentAccount(null)
            localStorage.setItem('isLoggedIn', 'false')
            localStorage.removeItem("currentUser")
            localStorage.setItem('connector','')
            props.setIsLoggedIn(false)
            setAccount(null)
            changePage('/','home')
            props.setDisconnectState(props.disconnectState + 1)
        }
        else{
            localStorage.setItem('account','');
            props.setCurrentAccount(null)
            localStorage.removeItem('isLoggedIn')
            localStorage.setItem('connector','')
            localStorage.removeItem("currentUser")
            props.setIsLoggedIn(false)
            props.disconnectWallet()
            setAccount(null)
            changePage('/','home')
            props.setDisconnectState(props.disconnectState + 1)
        }
    }

    const netSwitch = (n)=>{
        if(localStorage.getItem('connector')==="injected"){
            if(n === "mn"){
              props.switchMetaMaskNetwork("1")
            }
            else{
              props.addNetwork()
            }
          }
        else{
            if(n === "mn"){
                props.switchWalletConnectNetwork(0)
            }
            else{
                props.switchWalletConnectNetwork(1)
            }
        }
    }



    useEffect(()=>{
        const accnt = localStorage.getItem('account');
        let page = localStorage.getItem("currentPage");
        if(props.currentAccount !== null){
          setAccount(props.currentAccount)
        }
        else if(accnt){
          setAccount(accnt)
          props.setCurrentAccount(accnt)
        }
    
        if(page){
          props.setCurrentPage(page)
        }
    
    },[props.currentAccount,props.connectionState, props.disconnectState])

    return (
        <div className={sessionStorage.getItem("verification") ? "mobileHeader dont" : "mobileHeader"}>
            <Menu styles={{width:"100%"}} isOpen={menu} onStateChange={(state)=>{ setMenu(state.isOpen)}} width={"100%"} customBurgerIcon={ <LazyLoadImage src={Bar} alt="Menu bar icon"/> }>
                <a id="home" className="menu-item" onClick={()=>{changePage('/', 'home')}}>Home</a>
                <a id="about" className="menu-item" onClick={()=>{changePage('/marketplace', 'marketplace')}}>Marketplace</a>
                <a id="about" className="menu-item" onClick={()=>{changePage('/artists', 'artists')}}>Artists</a>
                <a id="contact" className="menu-item" onClick={()=>{changePage('/exhibition', 'exhibition')}}>Exhibition</a>
                <a id="contact" className='menu-item' onClick={()=>{changePage('/blogs', 'blogs')}}>Blogs</a>
                <div className='mobileHeaderButtons'>
                    <button className='Login' disabled={account !== null ? true : false} onClick={()=>{changePage('/login','home')}}>{account !== null ? `${account.substring(0,5)}....${account.substring(account.length-4,account.length)}` : "Login"}</button>
                    <button className={account === null ? "dontShow" : ""} onClick={()=>{logOut()}}>Sign Out</button>
                    <button className={account === null ? "dontShow" : ""} onClick={()=>{navigate('/profile')}}>Profile</button>
                    {/* <p>Supported Networks</p>
                    <button className={account === null ? "dontShow" : ""} onClick={()=>{netSwitch("mn")}}>ETH Mainnet</button>
                    <button className={account === null ? "dontShow" : ""} onClick={()=>{netSwitch("op")}}>Optimism</button> */}
                </div>
            </Menu>
            <LazyLoadImage src={Logo} alt="Creath" className='creath-logo'/>
            <button className='mobileLogin' disabled={account !== null ? true : false} onClick={()=>{changePage('/login','home')}}>{account !== null ? `${account.substring(0,5)}....${account.substring(account.length-4,account.length)}` : "Login"}</button>
        </div>
    );
}

export default MobileHeader;