import React, { useState, useEffect, useCallback} from 'react'
import './artcard.css'
import { useNavigate } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Modal from 'react-modal';
import Cancel from '../../assets/cancel.png';
import Like from '../Like/like'

const ArtCard = (props)=>{
    let subtitle;
    const [hasLiked, setHasLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [newFormat, setNewFormat] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const user_id = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser')).id
    : null;    

    const fetchLikeCount = useCallback(async () => {
        try {
          const response = await fetch(`https://creath.tech/api/arts/favorite-count/${props.id}`);
          const data = await response.json();
          setLikeCount(data.data);
        } catch (error) {
          console.error('Error fetching like count:', error);
        }
    }, [props.id]);


    const fetchLikedStatus = useCallback(async () => {
        if(user_id){
            try {
                const response = await fetch(`https://creath.tech/api/arts/favorite-status/${user_id}/${props.id}}`);
                const data = await response.json();
                setHasLiked(data.hasLiked);
            } catch (error) {
                console.error('Error fetching liked status:', error);
            }
        }
    }, [props.id, user_id]);

    useEffect(()=>{
        if(!props.img.includes("mp4")){
            let file = props.img.substr(0, props.img.lastIndexOf(".")) + ".webp";
            let adding = "q_80,w_600,h_600,c_fill/"
            let newString = file.slice(0, 29) + adding + file.slice(29);
            setNewFormat(newString);
        }else{
            let addings = "q_10/"
            let newStrings = props.img.slice(0, 29) + addings + props.img.slice(29);
            setNewFormat(newStrings);
        }
    },[props.img])

    
    useEffect(() => {
        fetchLikeCount();
        if (user_id) {
          fetchLikedStatus();
        }
    }, [fetchLikeCount, fetchLikedStatus, user_id]);


    const navigate = useNavigate();

       const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    Modal.setAppElement('#root');

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const setModalStatus = ()=>{
        setIsOpen(true);
    }

    const ArtProfile = {
        title : `${props.title}`,
        description : `${props.description}`,
        price : `${props.price}`,
        artist : `${props.artist}`,
        id : `${props.id}`,
        image : `${props.img}`,
        style: `${props.style}`,
        physical:  `${props.physical}`
    }

    const showProduct =  ()=>{
        props.setProduct(ArtProfile)
        navigate(`/product/${props.id}`)
    }

    const handleLike = useCallback(async () => {
        if(localStorage.getItem('isLoggedIn') === "true" && user_id){
          
            try {
                let data = new FormData()
                data.append('art_id', `${props.id}`)
                data.append('user_id', `${user_id}`)

                const response = hasLiked ? await fetch(`https://creath.tech/api/arts?art_id=${props.id}&user_id=${user_id}`, {
                    method: 'DELETE',
                  }) : await fetch('https://creath.tech/api/favorites',{
                    method: 'POST',
                    body: data
                })

                const status = await response.json();
                if (response.ok) {
                    fetchLikedStatus();
                    fetchLikeCount();
                } else {
                    console.error('Error updating like:', status.error);
                }
            } catch (error) {
                console.error('Error updating like:', error);
            }
        }
        else{
            setModalStatus()
        }
    },[hasLiked, fetchLikeCount, fetchLikedStatus, props.id, user_id])

    const showArtist =  ()=>{
        navigate(`/artistProfile?id=${props.artist}`)
    }

    return(
        <div className='artMain'>
            <div className='artImage'>
                {
                    props.img.includes('mp4') ? 
                    <video width="750" height="500"  autoPlay={true} muted={true} loop controls={false} playsInline>
                        <source src={props.img} type="video/mp4" />
                    </video>
                    :
                    
                        <LazyLoadImage
                            alt={props.title}
                            
                            src={newFormat}
                        />
                    
                }
            </div>
            <div className='artText'>
                <p className='artTitle'  onClick={showProduct}>{props.title}</p>
                <div className='subArtText'>
                <p className='artistName' onClick={showArtist}>{props.artistName}</p>
                <hr />
                <div className="priceLike">
                    <p className='artPrice'>{props.Art.collection_id === "7" ? "Not for Sale" : props.price + " USDC"}</p>
                    <div>
                        <Like like={hasLiked} onClick = {handleLike}/>
                        <p>{likeCount}</p>
                    </div>
                </div>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >   
                <div className='exhiModal'>
                    <h2 className='exhiModalHeader' ref={(_subtitle) => (subtitle = _subtitle)}>Note</h2>
                    <p>You need to sign in or sign up to like an artwork</p>

                </div>
                <div className='closeExhiModal' onClick={closeModal} role='button'>
                    <LazyLoadImage src={Cancel} alt="Close Modal"/>
                </div>
            </Modal>
        </div>
    )

}


export default ArtCard